



















import Vue from 'vue';
import DescriptionCol from '@/components/list/DescriptionCol.vue';
import ListWithFilter from '@/components/list/ListWithFilter.vue';

export default Vue.extend({
  name: 'ListDiagrams',
  components: {
    ListWithFilter,
    DescriptionCol,
  },
  computed: {
    searchUrl(): string {
      return `/org/${this.$route.params.orgId}/shared/diagrams/info`;
    },
  },
  data() {
    return {
      layers: [],
      extraColumns: [
        {
          name: 'code',
          label: 'Diagram Number',
          field: 'code',
          align: 'left',
          sortable: false,
        },
        {
          name: 'project',
          label: 'Project Label',
          field: 'project',
          align: 'left',
          sortable: false,
        },
        {
          name: 'description',
          label: 'Description',
          field: 'description',
          align: 'left',
          sortable: false,
        },
        {
          name: 'sharedUntil',
          label: 'Shared Until',
          field: 'sharedUntil',
          align: 'center',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    async goToView(row: any) {
      await this.$router.push(`/org/${this.$route.params.orgId}/shared-diagram/${row.id}/version/${row.versionId}`);
    },
  },
});
